var site = site || {};
var settings = Drupal.settings || {};
var generic = generic || {};
(function ($, generic) {
  // Open loyalty cancel content
  var overlay;
  var cancelReasonsText;
  var $cancelReasonslabel;
  var cancelReasonsCheckboxID;
  var $element;
  var $cancelMembershipButton;
  var loyaltyCancelationPopupHeight = typeof settings.loyalty === 'object'
    && typeof settings.loyalty.cancelation_popup === 'object'
    && typeof settings.loyalty.cancelation_popup.height === 'object' ? settings.loyalty.cancelation_popup.height : {};

  $('a.loyalty_cancel').click(function () {
    // overlay config
    overlay = {
      pc: {
        className: 'loyalty-cancel-overlay',
        width: '365px',
        height: loyaltyCancelationPopupHeight.pc ? loyaltyCancelationPopupHeight.pc : '480px',
        transition: 'none'
      },
      mobile: {
        className: 'loyalty-cancel-overlay',
        width: '90%',
        height: loyaltyCancelationPopupHeight.mobile ? loyaltyCancelationPopupHeight.mobile : '505px',
        transition: 'none'
      }
    };
    cancelReasonsText = '';
    site.offers.welcome15.renderOverlayPage('loyalty_cancellation_popup', overlay);

    // Do not vertical align the popup in mobile
    if (site.client.isMobile) {
      $('#foreground-node').css('top', '0');
    }
    $('.loyalty_popover__cancel_terms-text').addClass('hidden');
    $('.loyalty-cancel-overlay a.cancel_loyalty_submit').click(function (e) {
      e.preventDefault();
      if (cancelReasonsText === '') {
        return false;
      }
      // Cancel loyalty only if checkbox is enabled.
      var params = [
        {
          _SUBMIT: 'loyalty_join',
          LEAVE_CONFIRM: 1,
          LEAVE_CONFIRM_PRESENT: 1,
          LOYALTY_SOURCE: 'Site_Loyalty',
          LOYALTY_ACTIVE_FLAG: 0,
          LOYALTY_CANCELLATION_REASON: cancelReasonsText,
        },
      ];
      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: JSON.stringify(params),
        onSuccess: function() {
          window.location.href = '/account/profile_preferences.tmpl';
        },
        onFailure: function(r) {
          // it seems that not_found.user_attribute.item. error code is not relly an error (check commits 6eb49a96656a5, e04bf9b408d5d and search for 'not_found.user_attribute.item.')
          if (r.getError().data.messages[0].key === 'not_found.user_attribute.item.') {
            window.location.href = '/account/profile_preferences.tmpl';
          }
        }
      });
    });
    $('.loyalty_popover__buttons__retain').click(function () {
      $.colorbox.close();
    });
    // User has to select only one checkbox and remaining checkoxes should be disabled
    $('input[name="loyalty_cancellation_reasons"]').on('change', function () {
      $element = $(this);
      $cancelMembershipButton = $('.js-cancel_loyalty_submit');
      $('input[name="loyalty_cancellation_reasons"]').not($element).prop('checked', false);
      cancelReasonsCheckboxID = $element.attr('id');
      $cancelMembershipButton.addClass('button--disabled');
      cancelReasonsText = '';
      if ($element.is(':checked') && cancelReasonsCheckboxID !== 'undefined') {
        $cancelReasonslabel = $("label[for='" + cancelReasonsCheckboxID + "']");
        cancelReasonsText = $cancelReasonslabel.text();
        $cancelMembershipButton.removeClass('button--disabled');
      }
    });
  });
})(jQuery, window.generic || {});
